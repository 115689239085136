




































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Configuration, RedCapital, SelectedCountry, TitleTemplatePublic } from '@/configuration'
import DatatableFilterPlugin from '@/filters'
import API from '@/api'
import { mapState } from 'vuex'
import { EventBus } from '@/main'

Vue.use(DatatableFilterPlugin)

@Component({
  metaInfo: {
    title: 'Admin',
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    }
  },
  computed: {
    ...mapState(['user', 'token']),
    filteredDatatable() {
      return this.$data.users.filter((i: any) => Object.keys(this.$data.filters).every(f => this.$data.filters[f].length < 1 || this.$data.filters[f].includes(i[f])))
    },
    filtered2() {
      return this.$data.users.filter((i: any) => {
        return (i.nombre + ' ' + i.app_pat) == this.$data.filter.name || i.rut == this.$data.filter.dni || i.id == this.$data.filter.id
      })
    }
  },
  data() {
    return {
      RedCapital,
      SelectedCountry,
      canShow: false,
      users: null,
      isFiltering: false,
      filteredContent: null,
      filter: {
        id: '',
        dni: null,
        name: '',
      },
      filters: {
        id: null,
        dni: null,
        name: null,
      },
      currentToken: null,
      headers: [
        { text: 'Id', value: 'id' },
        // @ts-ignore
        { text: `${RedCapital[SelectedCountry].DniName}`, value: 'dni' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Acciones', value: 'actions' },
      ]
    }
  },
  async beforeMount() {
    this.$data.users = await API.admin.getAllUsers()
    this.$data.filters.id = this.$data.users.map((users: any) => users.id)
    this.$data.filters.dni = this.$data.users.map((users: any) => users.rut)
    this.$data.filters.name = this.$data.users.map((users: any) => users.nombre + ' ' + users.app_pat)
    this.$data.currentToken = this.$store.state.token
    this.$data.canShow = true
  },
  methods: {
    resetFilters() {
      this.$data.filter.id = ''
      this.$data.filter.dni = null
      this.$data.filter.name = ''
    },
    connect(id: number) {
      API.admin.loginBypass(id).then( (data: any) => {
        EventBus.$emit('snack-warning', 'Controlando usuario')
        setTimeout(() => {
          EventBus.$off('snack-warning')
          EventBus.$emit('snack-success', 'Usuario controlado')
          this.$store.commit('setUser', data.usuario)
          this.$store.commit('setToken', data.token)
          this.$router.push({
            path: '/panel/estadisticas'
          })
        }, 2000)
      }).catch( error => {
        EventBus.$emit('snack-error', error)
      })
    }
  }
})
export default class Asociate extends Vue {}
